import { useState, useEffect, React } from 'react'
import { useRecoilState } from 'recoil'
import config from '../lib/config';
import { loggedInState } from '../lib/state';
import TextInput from '../components/TextInput';
import Button from '../components/Button';
import Colors from '../lib/colors';

const Login = (props) => {

    const [loggedIn, setLoggedIn] = useRecoilState(loggedInState);
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();

    const handleSubmit = async() => {
        let formData = new FormData();
        formData.append('email', username);
        formData.append('password', password);
        let loginData = await fetch(config.baseUrl + "/auth/login", {
            method: 'post',
            credentials: 'include',
            body: formData})
        const loginJson = await loginData.json();
        if (loginJson.success == true) {
            setLoggedIn(loginJson.success);
        }
    }

    useEffect(() => {

    }, [loggedIn])

    return (
        <div style={{alignContent: 'center', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', position: 'fixed', display: 'flex', height: '100%', width: '100%'}}>
            <div>
                <TextInput style={{borderWidth: '2px', borderStyle: 'solid', borderColor: Colors.themeColor, fontSize: '2em', padding: '10px'}} type="text" onChange={setUsername} placeHolder="Username" /><br />
            </div>
            <div>
                <TextInput style={{borderWidth: '2px', borderStyle: 'solid', borderColor: Colors.themeColor, fontSize: '2em', padding: '10px'}} type="password" onChange={setPassword} placeHolder="Password" /><br />
            </div>
            <div>
                <Button style={{borderWidth: '2px', borderStyle: 'solid', borderColor: Colors.themeColor, backgroundColor: ""}} action={handleSubmit} onClick={handleSubmit} title="Login" />
            </div>
        </div>
    )
}

export default Login