import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
  } from 'recoil';

export const loggedInState = atom({
    key: 'loggedInState',
    default: false,
})

export const gotAuthState = atom({
    key: 'gotAuthState',
    default: false,
})

export const authDescriptionState = atom({
    key: 'authDescriptionState',
    default: {    
        "success": false,
        "status": {
            "displayName": "",
            "sessionId": "",
            "userUuid": "",
            "email": "",
            "orgId": "",
            "errors": "",
            "imageUrl": ""
        }
    }
})
