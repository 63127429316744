import React from 'react'
import Colors from '../lib/colors'
import { useEffect, useState } from 'react'
import styles from '../lib/Styles'
import config from '../lib/config'
import { BrowserRouter as Router, Switch, Route, Link, useParams} from 'react-router-dom'
import LeftNav from '../components/LeftNav'
import { Paper } from '@mui/material'


const Contacts = (props) => {

    const [contacts, setContacts] = useState([])
    const getContacts = async () => {
        const contactsData = await fetch(config.baseUrl + '/contacts/getContacts',{
                                        credentials: 'include',
                                        method: 'get'});
        const contactsJson = await contactsData.json();
        console.log(contactsJson);
        if (contactsJson.success == true) {
            setContacts(contactsJson.status);
        }
    }

    const renderContact = (contact, index) => {
        return(

            <Paper style={{marginTop: '5px', margin:'5px', width: '25vw', padding: '10px'}}>
                <span style={{fontWeight: 'bold', color: Colors.contactsColor, fontSize: '1.5em'}}>{contact.firstName} {contact.lastName}</span>
            </Paper>
        )
    }

    useEffect(() => {
        getContacts();
        console.log('Contacts')
    }, [])

    return (
        <div style={styles.mainLayout}>
            <LeftNav />
            <div style={styles.ContentContainer}>
                <h1 style={{paddingLeft: '15px', color: Colors.contactsColor}}>Contacts</h1>
                <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                {contacts.map((contact,index) => {
                    return(renderContact(contact,index));
                })}
                </div>
            </div>
        </div>
    )
}

export default Contacts