import React from 'react'
import Login from '../pages/login'
import Activity from '../pages/Activity'
import config from '../lib/config'
import { useEffect, useState } from 'react'
import { loggedInState } from '../lib/state'
import { gotAuthState } from '../lib/state'
import { authDescriptionState } from '../lib/state'
import { useRecoilState } from 'recoil'
import { BrowserRouter as Router, Switch, Route, Routes, Link, useParams} from 'react-router-dom'
import Assets from '../pages/Assets';
import Calendar from '../pages/Calendar';
import Contacts from '../pages/Contacts';
import Lists from '../pages/Lists';
import styles from '../lib/Styles'



const AppContainer = (props) => {

    var [loggedIn, setLoggedIn] = useRecoilState(loggedInState);
    var [gotAuth, setGotAuth] = useRecoilState(gotAuthState);
    var [authDescription, setAuthDescription] = useRecoilState(authDescriptionState);

    const whoAmI = async () => {
        console.log(config.baseUrl + "/auth/whoami");
        const whoData = await fetch(config.baseUrl + '/auth/whoami', 
                                        {credentials: 'include',
                                         method: 'get'});
        const whoJson = await whoData.json();
        console.log("This is who you are: whoJson", whoJson);
        setLoggedIn(whoJson.success);
        setGotAuth(true);
        console.log("Login json: ", whoJson);
    }

    const _router = () => {
        return(

        <Router>
            <Routes>
                <Route path="/" element={<Activity />} />
                <Route path="/lists" element={<Lists />} />
                <Route path="/assets" element={<Assets />} />
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/contacts" element={<Contacts />} />
            </Routes>
        </Router>
        )
    }

    useEffect(() => {
        whoAmI();
    }, [])

    if (gotAuth === true) {
        if (loggedIn == true) {
            return(
                <div style={styles.appContainer}>
                {_router()};
                </div>
            )
        }
        if (loggedIn == false) {
            return(
                <div style={styles.appContainer}>
                <Login />
                </div>
            )
        }
    } else {
        return(
            <div />
        )
    }
}

export default AppContainer