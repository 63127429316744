import React from 'react'
import Colors from '../lib/colors'
import { useEffect, useState } from 'react'
import styles from '../lib/Styles'
import config from '../lib/config'
import { BrowserRouter as Router, Switch, Route, Link, useParams} from 'react-router-dom'
import LeftNav from '../components/LeftNav'
import { colors, Paper } from '@mui/material'


const Lists = (props) => {

    const [lists, setLists] = useState([])
    const getLists = async () => {
        const listsData = await fetch(config.baseUrl + '/toDoLists/all',{
                                        credentials: 'include',
                                        method: 'get'});
        const listsJson = await listsData.json();
        console.log(listsJson);
        if (listsJson.success == true) {
            setLists(listsJson.status);
        }
    }

    const renderList = (list,index) => {
        console.log(list);
        return(
            <Paper style={{marginTop: '5px', margin:'5px', width: '25vw', padding: '10px'}}>
                <span style={{fontWeight: 'bold', color: Colors.listsColor, fontSize: '1.5em'}}>{list.title}<br /></span>
                {list.listItems.map((listItem, index) => {
                    return(<span>{listItem.itemTitle}<br /></span>)
                })}
            </Paper>
        )
    }

    useEffect(() => {
        getLists();
        console.log('Lists')
    }, [])

    return (
        <div style={styles.mainLayout}>
        <LeftNav />
        <div style={styles.ContentContainer}>
            <h1 style={{paddingLeft: '15px', color: Colors.listsColor}}>Lists</h1>
            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
            {lists.map((list,index) => {
                console.log(list.title);
                return(renderList(list,index));
            })}
            </div>
        </div>
    </div>
    )
}

export default Lists