import logo from './logo.svg';
import Login from './pages/login';
import Activity from './pages/Activity';
import { RecoilRoot } from 'recoil';
import { loggedInState } from './lib/state';
import { useRecoilState } from 'recoil';
import AppContainer from './components/AppContainer';
import { BrowserRouter as Router, Routes, Switch, Route, Link, useParams} from 'react-router-dom'
import Assets from './pages/Assets';
import Calendar from './pages/Calendar';
import Contacts from './pages/Contacts';
import Lists from './pages/Lists';
import React from 'react';


function App() {

  return (
    <RecoilRoot>
      <AppContainer />
    </RecoilRoot>
  );
}

export default App;
