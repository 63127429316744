import React from 'react'
import { useEffect, useState } from 'react'
import LogOutButton from '../components/LogOutButton'
import styles from '../lib/Styles'
import Colors from '../lib/colors'
import LeftNav from '../components/LeftNav'
import Card from '../components/Card'
import { Paper } from '@mui/material'
import config from '../lib/config'
import { BrowserRouter as Router, Switch, Route, Link, useParams} from 'react-router-dom'

const Activity = (props) => {

    let [feed, setFeed] = useState([]);

    const getFeed = async () => {
        const feedData = await fetch(config.baseUrl + "/feed/getFeed",
                                        {
                                            credentials: 'include',
                                            method: 'get'
                                        }
                                    );
        const feedJson = await feedData.json();
        console.log(feedJson);
        if (feedJson.success == true) {
            setFeed(feedJson.status);
        }
    }

    const renderFunction = (feedItem, index) => {
        let backgroundColor = "#f3f2f2";
        if (index%2==0) {
            backgroundColor = '#fff';
        }
        let preposition = "";
        let verb = "";
        let payload = "";
        let onPress = () => console.log("Click");
        switch (feedItem.itemType) {
            case "event":
                verb = "Created the event"
                break;
            case "contact": 
                verb = "Created the contact"; 
                break;
            case "comment": 
                verb = "Commented"; 
                preposition = "on"; 
                break;
            case "toDoList": 
                verb = "Created the list";
                break;
            case "toDoItem":
                verb = "Added the item"; 
                preposition = "to";
                if (feedItem.auxData == "complete") {
                    verb = "Completed the item";
                    preposition = "on";
                }
                if (feedItem.auxData == "revoke") {
                    verb = "reversed the completion of";
                    preposition = "on";
                }
                
                break;
            case "image": 
                verb = "Added this image"; 
                preposition = "to"; 
                break;
            case "tag":
                if (feedItem.auxData == "add") {
                    verb = "Added the tag"; preposition = "to"
                } else {
                    verb = "Removed the tag"; preposition = "from"
                }
                break;
            case "contactInteraction":
                console.log(feedItem.auxData);
                if (feedItem.itemTitle == "checkIn") {
                    payload = `checked ${feedItem.parentTitle} into ${feedItem.auxData.eventTitle}`
                }
                if (feedItem.itemTitle == "Meeting") {
                    payload = `met with ${feedItem.parentTitle}`
                }
                if (feedItem.itemTitle == "Email") {
                    payload = `emailed ${feedItem.parentTitle}`
                }
                if (feedItem.itemTitle == "Phone Call") {
                    payload = `checked ${feedItem.parentTitle} into ${feedItem.auxData.eventTitle}`
                }
                break;
            default:
                verb = "";
        }
        if (payload == "") {
            payload = `${verb} ${feedItem.itemTitle} ${preposition} ${feedItem.parentTitle}`;
        }
        let imageContainer = <div></div>
        if (feedItem.itemType == "image") {
            imageContainer = <div style={{paddingTop: 15, baddingBottom: 15, height: 300}}>
                <img 
                style={{width: 275, height: 275, borderRadius: 0, top: 15, bottom: 15 }} 
                src={feedItem.auxData} /> 
                </div>
        }
        return(
            <div key={feedItem.feedItemUuid} style={{display: 'flex', backgroundColor: backgroundColor, padding: 15, flexDirection: 'row'}}>
                <div>
                    <img style={{width: 50, height: 50, borderRadius: 50}} src={feedItem.userProfilePicture} />
                </div>
                <div onClick={onPress}>
                    <div style={{flex: 1, paddingLeft: 15, flexDirection: 'column'}}>
                        <span style={{fontWeight: 'bold'}}>{feedItem.UserDisplayName}</span>
                        <span>
                            {payload}
                            {imageContainer}
                        </span>
                    </div>
                </div>
            </div>
        )
}


    useEffect(() => {
        getFeed();
        console.log('Activity')
    }, [])

    return (

        <div style={styles.mainLayout}>
            <LeftNav />
            <div style={styles.ContentContainer}>
                <div style={{paddingLeft:'15px'}}>
                    <h1 onClick={getFeed} style={{color: 'salmon'}}>Feed</h1>
                </div>
                {feed.map((feedItem, index) => {
                    if (feedItem.itemTitle != "") {
                        return(renderFunction(feedItem, index))
                    }
                })}
            </div>
        </div>
    )
}

export default Activity;

