import React from 'react'
import { useEffect, useState } from 'react'
import Button from './Button'
import { loggedInState } from '../lib/state'
import { useRecoilState } from 'recoil'
import config from '../lib/config'

const LogOutButton = (props) => {

    const [loggedIn, setLoggedIn] = useRecoilState(loggedInState);

    useEffect(() => {
        console.log('LogOutButton')
    }, [])

    const logOutHandler = async() => {
        console.log("We loggin out of here.");
        const logoutData = await fetch(config.baseUrl + '/auth/logout', 
                                        {credentials: 'include',
                                         method: 'get'}
                                    );
        const logoutJson = await logoutData.json();
        console.log(logoutJson);
        //setlogin(loginJson.status);
        if (logoutJson.success == true) {
            setLoggedIn(false);
        }
        return(logoutJson);
    }

    return (
        <Button style={{backgroundColor: "", padding: ""}} title={"logout"} onClick={logOutHandler} />
    )
}

export default LogOutButton