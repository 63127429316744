import React from 'react'
import Colors from '../lib/colors'
import styles from '../lib/Styles'
import { useEffect, useState } from 'react'
import LogOutButton from './LogOutButton'
import { useNavigate } from 'react-router-dom'

const LeftNav = (props) => {

    let navigate = useNavigate();

    useEffect(() => {
        console.log('LeftNav')
    }, [])

    return (
        <div style={styles.leftNavContainer}>
            <div style={{
                        backgroundImage: `url("/tensileLogoIso.png")`,
                        color: "#ff0000",
                        height: '100px',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        paddingLeft: '10px',
                    }}>
            </div>
            <div style={styles.leftNavItem} onClick={() => {console.log("Click"); navigate("/")}}>
                Activity
            </div>
            <div style={styles.leftNavItem} onClick={() => {console.log("Click"); navigate("/lists")}}>
                Lists
            </div>
            <div style={styles.leftNavItem} onClick={() => navigate("/contacts")}>
                Contacts
            </div>
            <div style={styles.leftNavItem} onClick={() => navigate("/calendar")}>
                Calendar
            </div>
            <div style={styles.leftNavItem} onClick={() => navigate("/assets")}>
                Assets
            </div>
            <div style={{...styles.leftNavItem,borderWidth: '0px'}}>
                <LogOutButton />
            </div>
        </div>
    )
}

export default LeftNav