import Colors from './colors'

let styles = {
    mainLayout: {
        width: '100vw', 
        display: 'flex', 
        flexDirection: 'row'
    },
    appContainer: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: '100vh', 
        height: '100%',
        width: '100vw', 
        backgroundColor: "WhiteSmoke"
    },
    leftNavContainer: {
        display: 'flex', 
        width: '18.5%', 
        height: '100%',
        flexDirection: 'column',
        paddingLeft: '10px',
        paddingRight: '10px',
        borderRightColor: Colors.themeColor,
        borderRightWidth: "2px",
        borderRightStyle: "solid",
    },
    leftNavItem: {
        color: Colors.themeColor,
        paddingLeft: '5px', 
        paddingTop: '20px', 
        paddingBottom: '20px', 
        borderBottomWidth: "2px", 
        borderBottomStyle: 'solid', 
        borderBottomColor: Colors.themeColor,
    },
    ContentContainer: {
        padding: '0px',
        width: '100%',
    }
}


export default styles;