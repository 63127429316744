import React from 'react'
import Colors from '../lib/colors'
import { useEffect, useState } from 'react'
import styles from '../lib/Styles'
import { BrowserRouter as Router, Switch, Route, Link, useParams} from 'react-router-dom'
import LeftNav from '../components/LeftNav'

const Assets = (props) => {

    useEffect(() => {
        console.log('Assets')
    }, [])

    return (
        <div style={styles.mainLayout}>
            <LeftNav />
            <div style={styles.ContentContainer}>
                <h1 style={{color: 'salmon'}}>
                    Assets
                </h1>
            </div>
        </div>
    )
}

export default Assets