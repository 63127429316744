import React from 'react'
import Colors from '../lib/colors'
import { useEffect, useState } from 'react'
import styles from '../lib/Styles'
import config from '../lib/config'
import { BrowserRouter as Router, Switch, Route, Link, useParams} from 'react-router-dom'
import LeftNav from '../components/LeftNav'
import { Calendar as _Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css"
import { colors } from '@mui/material'

const localizer = momentLocalizer(moment)

const Calendar = (props) => {

    const [today, setToday] = useState(new Date());
    const [calendar, setCalendar] = useState([])
    const [events, setEvents] = useState([]);

    const addHours = (numOfHours, date = new Date()) => {
        date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
        return date;
      }

    const processEvents = () => {
        let _events = [];
        calendar.map((event, index) => {
            let d = new Date(event.eventStart);
            let e = new Date(event.eventEnd);
            let start = `${d.getFullYear()}-${(d.getMonth()+1).toString().padStart(2, '0')}-${(d.getDate()).toString().padStart(2, '0')}`
            let end = `${e.getFullYear()}-${(d.getMonth()+1).toString().padStart(2, '0')}-${(d.getDate()).toString().padStart(2, '0')}`
            let _event = {
                    id: index,
                    title: event.eventTitle,
                    start: new Date(event.eventStart),
                    allDay: true,
                    end: event.eventEnd == "" ? addHours(1, new Date(event.eventStart)) : new Date(event.evetnEnd),
                    eventUuid: event.eventUuid,
            } 
            _events.push(_event);
            setEvents(_events);
        })
        console.log("Events: ", events);
    }

    const getCalendar = async () => {
        const calendarData = await fetch(config.baseUrl + '/events/getEvents',{
                                        credentials: 'include',
                                        method: 'get'});
        const calendarJson = await calendarData.json();
        console.log(calendarJson);
        if (calendarJson.success == true) {
            setCalendar(calendarJson.status);
        }
    }

    useEffect(() => {
        getCalendar();
    }, [])

    useEffect(() => {
        processEvents();
    }, [calendar])

    return (
        <div style={styles.mainLayout}>
            <LeftNav />
            <div style={{paddingLeft: '15px', paddingRight: '15px',...styles.ContentContainer}}>
                <h1 style={{color: Colors.calendarColor}}>Calendar</h1>
                    <div>
                        <_Calendar
                            events={events}
                            localizer={localizer}
                            startAccessor="start"
                            endAccessor="end"
                            selectable
                            onSelectEvent={(e) => {console.log(e)}}
                            style={{ height: 500 }}
                        />
                    </div>
            </div>
        </div>
    )
}

export default Calendar
