import React from 'react'
import { useEffect, useState } from 'react'

const Button = (props) => {
    useEffect(() => {
        console.log('Button')
    }, [])

    return (
        <a style={{
                    display: 'inline-block', 
                    border: '0px', 
                    padding: 10, 
                    backgroundColor: 'salmon',
                    ...props.style
                }} 
            onClick={props.onClick}>
            {props.title}
        </a>
    )
}

export default Button