import React from 'react'
import { useEffect, useState } from 'react'

const TextInput = (props) => {

    var type = typeof(props.inputType) == "undefined" ? "text" : props.type;

    useEffect(() => {
    }, [])

    const handleChange = (e) => {
        props.onChange(e.target.value);
    }

    return (
        <input style={{
                        padding: '4px', 
                        outline: 'none', 
                        marginTop: '10px', 
                        marginBottom: '10px', 
                        border: '0px',
                        ...props.style
                    }} 
                type={props.type} 
                placeHolder={props.placeHolder} 
                onChange={handleChange} />
    )
}

export default TextInput